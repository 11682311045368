import React from "react"
import Helmet from "react-helmet"
import LeftColumn from "../components/leftColumn"
import Cookie from "../components/cookie"
import SocialMobile from "../components/socialMobile"
import {titlesTranslation} from "../data/titles/titlesTranslations";
import {Link} from "gatsby";

export default function Contact(data) {
    // console.log(data);
    let language = data.pageContext.language;
    let t = data.pageContext.t;
    let titles = titlesTranslation[language];

    let handleInputChange = event => {};

    let handleSubmit = event => {
        event.preventDefault();
        console.log(event.target);

        let formData = new FormData(event.target);
        fetch("/api/email.php", {
            method: 'POST',
            body: formData
        }).then(r => {
            return r.json();
        }).then(r => {
            console.log(r);
            if(r.status === 'ok'){
                ///
                document.getElementById("contact-message").classList.remove("hidden");
                document.getElementById("contact-message__name").innerText = formData.get('fullName').toString();
                document.getElementById("contact-form").classList.add("hidden");

                document.querySelector(".contact-form__link").addEventListener('click', () => {
                    document.getElementById("contact-form").classList.remove("hidden");
                    document.getElementById("contact-message").classList.add("hidden");
                });


                document.getElementById("contact-form").reset();
                // let contactFormItems = document.querySelectorAll(".contact-form__data-item input");
                // contactFormItems.forEach((elem) => {
                //     elem.value = '';
                // });
                //
                // let contactFormTextarea = document.querySelector(".contact-form__data-text");
                // contactFormTextarea.value = '';

            }else {
                ///
            }
        });
    };

    return (
        <div className="page">
            <Helmet>
                <title>{titles.contact}</title>
                <meta name="description" content={titles.description}/>
            </Helmet>

            <LeftColumn language={language} data={data}/>

            <div className="right-col">
                <h1>{t.contact_header}</h1>

                <div className="contact-info sub-block-mt-88">
                    <div className="contact-info__item">
                        info@itset.ee<br/>
                        +372 56088086
                    </div>
                    <div className="contact-info__item" dangerouslySetInnerHTML={{__html: t.contact_address}}/>
                </div>

                <div className="contact-form block-mt-88">
                    <form id="contact-form" onSubmit={handleSubmit}>
                        <h2 className="contact-form__header mb-56 max-w-636" dangerouslySetInnerHTML={{__html: t.contact_form_header}}/>
                        <div className="contact-form__data">
                            <div className="contact-form__data-item">
                                <div className="contact-form__data-label fs-14">{t.contact_form_name}</div>
                                <input type="text" name="fullName" className="contact-form__data-name fs-18" onChange={handleInputChange} required={true}/>
                            </div>
                            <div className="contact-form__data-item">
                                <div className="contact-form__data-label fs-14">{t.contact_form_organization}</div>
                                <input type="text" name="organizationName" className="contact-form__data-organization fs-18" onChange={handleInputChange} />
                            </div>
                            <div className="contact-form__data-item">
                                <div className="contact-form__data-label fs-14">{t.contact_form_phone}</div>
                                <input type="tel" name="phone" className="contact-form__data-phone fs-18" placeholder="+372" onChange={handleInputChange} />
                            </div>
                            <div className="contact-form__data-item">
                                <div className="contact-form__data-label fs-14">{t.contact_form_email}</div>
                                <input type="email" name="email" className="contact-form__data-email fs-18" onChange={handleInputChange} required={true}/>
                            </div>
                            <div className="contact-form__data-item contact-form__request-text">
                                <div className="contact-form__data-label fs-14">{t.contact_form_text}</div>
                                <textarea name="requestText" rows="5" className="contact-form__data-text fs-18" onChange={handleInputChange} required={true}/>
                            </div>
                        </div>
                        <div className="contact-btn">
                            <button className="btn" type="submit" aria-label="Send request">
                                {t.contact_form_btn} <img className="btn__img" src="/images/btn-arrow.svg" alt=""/>
                            </button>
                        </div>
                    </form>

                    <div id="contact-message" className="hidden" >
                        <h2 className="contact-form__header max-w-636">{t.contact_message_header}, <span id="contact-message__name">John</span></h2>
                        <div className="contact-form__subtitle max-w-636">{t.contact_message_subtitle}</div>
                        <div className="contact-form__text fs-18 max-w-636">
                            {t.contact_message_text}
                        </div>
                        <Link to={data.pageContext.currentMenuItem[language].slug} className="contact-form__link">
                            {t.contact_message_btn}
                        </Link>
                    </div>
                </div>
                <SocialMobile/>
            </div>
            <Cookie language={language}/>
        </div>
    );
}